import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const StyledFooter = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #333;

  color: #fff;
  font-size: 16px;
  text-align: left;

  a {
    text-decoration: none;
    color: #929292;
    transition: color 0.1s ease;

    &:hover {
      color: #fff;
    }
  }

  span.copyright {
    color: #929292;
  }

  ${props =>
    props.mode === "mobile" &&
    `
    position: absolute;
    bottom: 20px;
    background: none;
    padding-top: 0;
    padding-bottom: 20px;
    font-size: 18px;
    line-height: 2rem;
    font-family: NewlineTextMedium;
    width: 100%;

    

    & > .container {
      width: auto;
      padding-top: 20px;


      .col-12 {
      padding-top: 20px;

      }
    }

    span.copyright, 
    span.privacypolicy,
    span.termsofuse {
      display: none;
    }
  `};
`;

const Footer = ({ siteTitle, currentPage, mode }) => (
  <StyledFooter mode={mode}>
    <div className="container">
      <div className="row col-12">
        <div className="col-md-2 ">
        <Link to="/purpose" title="Purpose">
            Purpose
          </Link>
          <br />
        <Link to="/contact" title="Contact">
            Contact
          </Link>
          <br />
          <Link to="/careers" title="Careers">
            Careers
          </Link>
          <br />
        </div>

        <div className="col-md-2 ">
        <a href="https://www.facebook.com/unocup/" target="_blank">
            Facebook
          </a>
          <br />
          <a href="https://www.instagram.com/introducing.uno/" target="_blank">
            Instagram
            </a>
          <br />
          <a href="https://twitter.com/introducingUno/" target="_blank">
            Twitter
          </a>
          <br />
        </div>

        <div className="col-md-4">
        <span className="copyright">
          <Link to="/privacy-policy" title="Privacy Policy">
            Privacy Policy
          </Link>
          <br />
          <Link to="/terms-of-use" title="Terms of Use">
            Terms of Use
          </Link>
          <br />
          © 2020 Uno Cup, Inc.</span>
        </div>


      </div>
    </div>
  </StyledFooter>
);

export default Footer;
