import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`  
  body {
    font-family: "NewlineTextMedium";
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    background: #f5f4f2;
    letter-spacing: .01rem;
    font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1;
    -webkit-font-smoothing: antialiased;
 


    html.homepage & {
      color: #f5f4f2;
    }

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  strong {
    font-family: "NewlineTextBold";
  }
  
  ul.job {
    list-style-type: none;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  a.plainlink {
    color: #004AD8;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    padding-bottom: 3px;
    transition: border 0.1s ease;

    &:hover {
      color: $004AD8;
      border-bottom: 2px solid #004AD8;
      padding-bottom: 3px;
    }
  }

  h1 {
    font-family: "NewlineBold";
    font-size: 36px;
    line-height: 42px;
    margin: 0;
    letter-spacing: .02em;
    font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1;
    -webkit-font-smoothing: antialiased;

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  h2 {
    font-family: "NewlineRegular";
    font-size: 32px;
    line-height: 48px;
    margin: 10px 0 0 0;
    font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1;

    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  h3 {
    font-family: "NewlineTextBold";
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 8px;
    padding-left: 10px;
    font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1;

    @media (max-width: 767px) {
      font-size: 10px;
    }
  }

  h4 {
    font-family: "NewlineRegular";
    font-size: 50px;
    line-height: 75px;
    letter-spacing: 0.15rem;
    font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1;

    @media (max-width: 767px) {
      font-size: 36px;
      line-height: 38px;
      padding: 0 3rem;
    }   
  }

  p {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }


  video, img {
    display: block;

    &.full-width {
      object-fit: cover;
      width: 100%;
      height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    
    &.contained {
      width: 100%;
      margin: 50px auto 0;
    }

    &.hidden {
      visibility: hidden;
      position: absolute;
    }
  }

  .thispagetitle {
    font-family: "NewlineTextBold";
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 8px;
    line-height: 100px;
    padding-left: 8px;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  video::-webkit-media-controls-overlay-play-button {
    display: none;
  }

  .aligned-left { text-align: left; }
  .aligned-right { text-align: right; }
  .bold-text { font-family: "NewlineTextBold"; } 

  .jobdescription {
    text-align: left;
    padding-bottom: 60px;
  }

  .bold-text.jobsectiontitle {
    padding-bottom: 30px;
  }

  p.backbutton {
    text-align: left;
    margin-bottom: 100px;
    margin-top: 150px;
  }

  h1.jobtitle {
    text-align: left;
    padding-bottom: 40px;
  }

  .roletitle {
    padding-bottom: 100px;
    display: block;
  }

  ul {
    list-style: none;
    padding-left: 0px;
  }

  .jobsection > li:last-child {
    padding-bottom: 50px;
  }

  .jobsection {
    border-bottom: 1px;
    border-bottom-style: solid;
    border-color: #000000;
    margin-bottom: 50px;
    width: auto;
  }

  .jobsection > li {
    padding-bottom: 20px;
  }

  .joblisting > li {
    text-align: left;
    padding-bottom: 10px;
  }

  ul.joblisting {
    list-style: none;
    padding-bottom: 20px;
  }

  .legalcopy {
    font-size: 17px;
    }
   
  .legalcopy > p {
    margin-top: 15px;
  }  

  .standard-cta {
    position: absolute;
    left: 0;
    margin-left: 50%;
    border: 0;
    transform: translateX(-50%);
    border-radius: 30px;
    text-transform: uppercase;
    padding: 15px 40px 15px 44px;
    margin-top: 15px;
    font-family: NewlineTextBold;
    font-size: 12px;
    letter-spacing: 3px;
    outline: none;
    background: #fff;
    transition: 0.2s;
    color: #000;
    margin-top: 40px;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      background-color: #003dcf;
      color: #fff;
      text-decoration: none;
    }

    @media (max-width: 767px) {
      min-width: 220px;
      margin-top: 50px;
      padding: 15px 0px 15px 0px;
    }

`;

export default GlobalStyle;
