import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import favIcon from "../images/favicon.png";
import GlobalStyle from "./global-style";

// import "jquery";
// import "bootstrap";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children, currentPage }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: "description", content: "Meet the Uno Cup. A new way for beverages." },
            { name: "keywords", content: "Uno Cup, Sustainability, Zero Waste, Zero Carbon, Reusable Cup, Circular Economy, Beverages" },
          ]}
        >
          <html lang="en" className={currentPage} />
          <link rel="shortcut icon" href={favIcon} />
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-128225735-1"
          />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-128225735-1');
            `}
          </script>
          <script
            src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
            integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
            crossOrigin="anonymous"
          />
        </Helmet>
        <Header
          siteTitle={data.site.siteMetadata.title}
          currentPage={currentPage}
        />
        <div className="content-wrapper">{children}</div>
        {currentPage !== "homepage" && <Footer />}
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
          integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
          crossOrigin="anonymous"
        />
        <script
          src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
          integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
          crossOrigin="anonymous"
        />

        <GlobalStyle />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
