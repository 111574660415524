import React from "react";
import { Link } from "gatsby";
import Footer from "./footer";
import logo from "../images/uno-logo.png";
import whiteLogo from "../images/uno-logo-white.png";
import mobileMenuButton from "../images/mobile-menu-button.png";
import MediaQuery from "react-responsive";

import styled from "styled-components";

const darkPages = ["homepage", "problem", "careers", "port", "contact"];

const StyledHeader = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  z-index: 150;

  .uno-logo {
    width: 80px;
  }

  .uno-logo-mobile {
    width: 80px;
    display: none;
  }

  font-family: "NewlineTextMedium";
  font-size: 16px;

  @media (max-width: 767px) {
    .navbar {
      padding: 20px;
    }
  }

  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    background: #000;
    font-size: 28px;

    .uno-logo {
      display: none;
    }

    .uno-logo-mobile {
      display: inline;
    }

    .navbar-toggler {
      width: 36px;
      border: 0;
      padding-right: 0px;

      img {
        width: 100%;
        transition: transform 0.3s ease;
      }

      &.open {
        img {
          transform: rotate(180deg);
        }
      }
    }
  }

  .navbar-nav {
    html.homepage &,
    html.problem &,
    html.careers &,
    html.contact &,
    html.port & {
      .nav-link {
        color: #fff;

        &:hover {
          color: #fff;
          border-bottom: 2px solid #fff;
          padding-bottom: 3px;
        }
      }
    }
    .nav-link {
      color: #000;
      text-decoration: none;
      padding-left: 0rem;
      padding-right: 0rem;
      margin-left: 2rem;
      margin-right: 0rem;
      padding-bottom: 3px;
      border-bottom: 2px solid transparent;
      transition: border 0.1s ease;
      font-family: "NewlineTextBold";

      &:hover {
        color: #000;
        border-bottom: 2px solid #000;

        @media (max-width: 767px) {
          border-bottom: 2px solid transparent !important;
        }
      }
    }
  }
  .navbar-collapse {
    &.collapsing,
    &.show {
      @media (max-width: 767px) {
        background: rgba(0, 0, 0, 1);
      }
    }
  }

  html.homepage & {
    position: fixed;
  }
`;

const MobileMenuWrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: -100%;
  opacity: 0;
  left: 0;
  right: 0;
  height: calc(100% - 54px);
  background: rgba(0, 0, 0, 1);
  color: #fff;
  text-align: left;
  transition: opacity 0.3s ease, top 0.3s ease;
  padding-top: 40px;
  font-family: "NewlineTextBold";

  ul.navbar-nav {
    line-height: 2rem;
  }

  a.nav-link {
    margin-left: 1rem;
    color: #fff;
    font-size: 20px;
    text-decoration: none;

    &:hover,
    &:active {
      color: #fff;
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    &.open {
      top: 54px;
      opacity: 1;
    }
  }
`;

function chooseLogo(currentPage) {
  if (darkPages.includes(currentPage)) {
    return whiteLogo;
  } else {
    return logo;
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
    };
  }

  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  renderMenu() {
    return (
      <ul className="navbar-nav mt-2 mt-lg-0">
        <li className="nav-item">
          <Link to="/problem" className="nav-link" onClick={this.closeMenu}>
            The Problem
          </Link>
        </li>
        {/*<li className="nav-item">
          <Link to="/watch" className="nav-link" onClick={this.closeMenu}>
            Our Solution
          </Link>
        </li>
    */}
        <li className="nav-item">
          <MediaQuery key="desktop" query="(min-width: 768px)">
            <Link to="/reserve" className="nav-link" onClick={this.closeMenu}>
              Reserve 
            </Link>
          </MediaQuery>

          <MediaQuery key="mobile" query="(max-width: 767px)">
            <Link
              to="/reserve-mobile"
              className="nav-link"
              onClick={this.closeMenu}
            >
              Reserve 
            </Link>
          </MediaQuery>
        </li>

        {/*<li className="nav-item">
          <Link to="/contact" className="nav-link" onClick={this.closeMenu}>
            Contact
          </Link>
        </li>
        */}
      </ul>
    );
  }

  render() {
    const { siteTitle, currentPage } = this.props;
    const { menuOpen } = this.state;

    return [
      <StyledHeader key="styled-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav
                className={`navbar navbar-expand-md ${
                  darkPages.includes(currentPage)
                    ? "navbar-dark"
                    : "navbar-light"
                } `}
              >
                <Link to="/" title={siteTitle} className="navbar-brand">
                  <img
                    alt={siteTitle}
                    className="uno-logo"
                    src={chooseLogo(currentPage)}
                  />
                  <img
                    className="uno-logo-mobile"
                    alt={siteTitle}
                    src={whiteLogo}
                  />
                </Link>

                <button
                  className={`navbar-toggler ${menuOpen ? "open" : ""}`}
                  onClick={() => {
                    this.setState({ menuOpen: !this.state.menuOpen });
                  }}
                >
                  <img src={mobileMenuButton} alt="Menu" />
                </button>

                <div className="collapse navbar-collapse justify-content-end">
                  {this.renderMenu()}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </StyledHeader>,
      <MobileMenuWrapper className={menuOpen ? "open" : ""} key="mobile-menu">
        <div className="container">
          <div className="row">
            <div className="col-6">{this.renderMenu()}</div>
          </div>
        </div>
        <Footer mode="mobile" />
      </MobileMenuWrapper>,
    ];
  }
}

export default Header;
